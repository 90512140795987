@import '../../../../scss/theme-bootstrap';

/*
 * These values determine the size of the shade window,
 * which is what the user can actually see. The product
 * shade picker JS then takes care of the scrolling within
 * the shades container.
 */
$hex-width: 40px;
$hex-margin: 1px;
$hex-rows: 2;
$spp-hex-rows: 4;
$mobile-spp-hex-rows: 5;
$hex-cols: 10;
$calc-width: $hex-cols * ($hex-width + $hex-margin);
$calc-height: $hex-rows * ($hex-width + $hex-margin);
$spp-calc-height: $spp-hex-rows * ($hex-width + $hex-margin);
$mobile-spp-calc-height: $mobile-spp-hex-rows * ($hex-width + $hex-margin);
$active-shade-border-width: 2px;

.product-shade-picker {
  @include pie-clearfix;
  display: inline-block;
  position: relative;
  width: 100%;
  // padding-top: 1em;

  &__shades-window {
    display: inline-block;
    overflow: hidden;
    width: 97%;
    max-width: $calc-width;
    max-height: $calc-height;
    .product-full__details & {
      max-height: $mobile-spp-calc-height;
      @include breakpoint($portrait-up) {
        max-height: $spp-calc-height;
      }
    }
  }
  &__shades {
    @include pie-clearfix;
    position: relative;
    top: 0;
    font-size: 0;
    white-space: nowrap;
    width: 100%;
    @include breakpoint($landscape-up) {
      float: left;
      width: auto;
    }
  }
  &__shade {
    float: left;
    width: $hex-width;
    height: $hex-width;
    margin-bottom: $hex-margin;
    margin-right: $hex-margin;
    .icon--close {
      color: $color-black;
      font-size: $hex-width;
    }
    &.active {
      border: $active-shade-border-width solid $color-black;
      .icon--close {
        margin-top: -$active-shade-border-width;
        margin-left: -$active-shade-border-width;
      }
    }

    // Special styles for better contrast with dark shades.
    &--dark {
      .icon--close {
        color: $color-white;
      }
      &.active {
        border-color: $color-white;
      }
    }
  }
  &__controls {
    display: inline-block;
    position: absolute;
    width: 8%;
    height: $calc-height;
    max-width: $hex-width;
    .product-full__details & {
      height: $mobile-spp-calc-height;
      @include breakpoint($portrait-up) {
        height: $spp-calc-height;
      }
    }
  }
  &__arrow {
    position: absolute;
    font-size: 1em;
    left: 0.5em;
    outline: 0;
    &:hover {
      text-decoration: none;
    }
    &--up {
      top: 0;
    }
    &--down {
      bottom: 0;
    }
  }
  &__shade-desc {
    text-transform: uppercase;
  }
  &__shade-desc-label {
    font-weight: bold;
  }
  /// shade picker for multi shaded products
  &__shades--multi {
    display: inline-block;
    margin-right: 15px;
    position: relative;
    &:hover {
      text-decoration: none;
    }
    &.product-shade-picker__shades--multi-img {
      float: left;
      margin: 0;
    }
  }
  &__shade-multi {
    @include circle-plain(40px);
    border: 2px solid transparent;
    display: block;
    float: left;
    line-height: 42px;
    margin: 0 2px 2px 0;
    .active & {
      border-color: $color-black;
    }
  }
}
// specific override styles for sold out multishades
.product-shade-picker__shades--multi--oos {
  .product-shade-picker__shade-multi {
    background-color: $dark-gray;
    border: 2px solid $color-white;
    .product-quickshop & {
      border: 2px solid $color-bg;
    }
  }
}

.product-shade-picker__shades--multi--oos.product-shade-picker__shades--multi--oos-image
  .product-shade-picker__shade-multi {
  background-color: transparent;
}

.product-shade-picker__shades--multi--oos.active .product-shade-picker__shade-multi {
  border-color: $color-black;
}
